<template>
    <section class="d-flex flex-row align-center col-xl-3 col-lg-4 pa-0 mb-5">
        <v-text-field
            :solo="!outlined"
            :outlined="outlined"
            dense
            flat
            class="custom-border general-custom-field poppins fw500"
            hide-details
            prepend-inner-icon="mdi-magnify"
            :placeholder="placeholder"
            :value="value"
            :loading="loading"
            @input="$emit('update:value', $event)"
        />
        <v-btn color="primary" class="poppins" @click="$emit('search')"> Search</v-btn>
    </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        placeholder: {
            type: String,
            default: 'Search'
        },
        value: {
            type: String,
            default: ''
        },
        outlined: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
})
</script>